import {Route} from 'entity-manager';
import {CrossDependency} from '../../../../projects/entity-manager/src/lib/decorator/cross-dependency';
import {Meta} from '../../../../projects/entity-manager/src/lib/service/meta/meta';
import {
    Employee,
    PaymentDeductionType,
} from './entities';
import {AssociationOne} from '../../../../projects/entity-manager/src/lib/decorator/associations/association-one';
import {PropertyPayloadModifier} from '../../../../projects/entity-manager/src/lib/decorator/payload-modifiers/property-payload-modifier';
import {DecimalPropertyPayloadModifier} from '../../../../projects/entity-manager/src/lib/decorator/payload-modifiers/types/decimal-property-payload-modifier';
import {EmployeeMonthlySalaryCalculation} from './employee-monthly-salary-calculation';

@Route('monthly_wages')
@CrossDependency(() => {
    return {
        employee: {
            mapping: Meta.META_ASSOCIATIONS,
            entity: Employee
        },
        paymentDeductionType: {
            mapping: Meta.META_ASSOCIATIONS,
            entity: PaymentDeductionType
        },
        employeeMonthlySalaryCalculations: {
        mapping: Meta.META_ASSOCIATIONS,
            entity: EmployeeMonthlySalaryCalculation
    }
};
})
export class MonthlyWage {

    @AssociationOne(Employee)
    protected employee: Employee;

    @AssociationOne(PaymentDeductionType)
    protected paymentDeductionType: PaymentDeductionType;

    @AssociationOne(EmployeeMonthlySalaryCalculation)
    protected employeeMonthlySalaryCalculations: EmployeeMonthlySalaryCalculation;


    protected id: string;

    @PropertyPayloadModifier(DecimalPropertyPayloadModifier)
    protected monthlyWage: string;

    @PropertyPayloadModifier(DecimalPropertyPayloadModifier)
    private baseWorkPositionSalaryAddition;

    @PropertyPayloadModifier(DecimalPropertyPayloadModifier)
    private baseWorkPositionSalarySickLeaveAddition;

    @PropertyPayloadModifier(DecimalPropertyPayloadModifier)
    private baseWorkPositionSalaryHolidayAddition;

    @PropertyPayloadModifier(DecimalPropertyPayloadModifier)
    protected previousWork: string;

    @PropertyPayloadModifier(DecimalPropertyPayloadModifier)
    protected bonus: string;

    @PropertyPayloadModifier(DecimalPropertyPayloadModifier)
    protected nightShift: string;

    @PropertyPayloadModifier(DecimalPropertyPayloadModifier)
    protected stimulus: string;

    @PropertyPayloadModifier(DecimalPropertyPayloadModifier)
    protected holidayAddition: string;

    @PropertyPayloadModifier(DecimalPropertyPayloadModifier)
    protected overtimeAddition: string;

    @PropertyPayloadModifier(DecimalPropertyPayloadModifier)
    protected deduction: string;
    @PropertyPayloadModifier(DecimalPropertyPayloadModifier)
    protected deductionMediaGroup: string;
    @PropertyPayloadModifier(DecimalPropertyPayloadModifier)
    protected deductionAdministrativeBan: string;
    @PropertyPayloadModifier(DecimalPropertyPayloadModifier)
    protected deductionAdministrativeBan2: string;
    @PropertyPayloadModifier(DecimalPropertyPayloadModifier)
    protected deductionAdministrativeBan3: string;
    @PropertyPayloadModifier(DecimalPropertyPayloadModifier)
    protected deductionMobilPhone: string;
    @PropertyPayloadModifier(DecimalPropertyPayloadModifier)
    protected deductionReduction: string;

    protected paymentType: string;
    protected isMonthlySalaryCalculationRegulated: boolean;

    protected entryYear: string;
    protected entryMonth: string;

    public setId(id: string): MonthlyWage {
        this.id = id;
        return this;
    }

    public getId(): string | undefined {
        return this.id;
    }

    public setEmployee(employee: Employee): MonthlyWage {
        this.employee = employee;
        return this;
    }

    public getEmployee(): Employee|undefined {
        return this.employee;
    }

    public setMonthlyWage(monthlyWage: string): MonthlyWage {
        this.monthlyWage = monthlyWage;
        return this;
    }

    public getMonthlyWage(): string {
        return this.monthlyWage;
    }

    public setBaseWorkPositionSalaryAddition(baseWorkPositionSalaryAddition: string): MonthlyWage {
        this.baseWorkPositionSalaryAddition = baseWorkPositionSalaryAddition;
        return this;
    }

    public getBaseWorkPositionSalaryAddition(): string {
        return this.baseWorkPositionSalaryAddition;
    }

    public setBaseWorkPositionSalarySickLeaveAddition(baseWorkPositionSalarySickLeaveAddition: string): MonthlyWage {
        this.baseWorkPositionSalarySickLeaveAddition = baseWorkPositionSalarySickLeaveAddition;
        return this;
    }

    public getBaseWorkPositionSalarySickLeaveAddition(): string {
        return this.baseWorkPositionSalarySickLeaveAddition;
    }

    public setBaseWorkPositionSalaryHolidayAddition(baseWorkPositionSalaryHolidayAddition: string): MonthlyWage {
        this.baseWorkPositionSalaryHolidayAddition = baseWorkPositionSalaryHolidayAddition;
        return this;
    }

    public getBaseWorkPositionSalaryHolidayAddition(): string {
        return this.baseWorkPositionSalaryHolidayAddition;
    }

    public setPreviousWork(previousWork: string): MonthlyWage {
        this.previousWork = previousWork;
        return this;
    }

    public getPreviousWork(): string {
        return this.previousWork;
    }

    public getHolidayAddition(): string {
        return this.holidayAddition;
    }

    public setHolidayAddition(holidayAddition: string): MonthlyWage {
        this.holidayAddition = holidayAddition;
        return this;
    }

    public getOvertimeAddition(): string {
        return this.overtimeAddition;
    }

    public setOvertimeAddition(overtimeAddition: string): MonthlyWage {
        this.overtimeAddition = overtimeAddition;
        return this;
    }

    public getDeduction(): string {
        return this.deduction;
    }

    public setDeduction(deduction: string): MonthlyWage {
        this.deduction = deduction;
        return this;
    }

    public getDeductionMediaGroup(): string {
        return this.deductionMediaGroup;
    }

    public setDeductionMediaGroup(deductionMediaGroup: string): MonthlyWage {
        this.deductionMediaGroup = deductionMediaGroup;
        return this;
    }

    public getDeductionAdministrativeBan(): string {
        return this.deductionAdministrativeBan;
    }

    public setDeductionAdministrativeBan(deductionAdministrativeBan: string): MonthlyWage {
        this.deductionAdministrativeBan = deductionAdministrativeBan;
        return this;
    }

    public getDeductionAdministrativeBan2(): string {
        return this.deductionAdministrativeBan2;
    }

    public setDeductionAdministrativeBan2(deductionAdministrativeBan2: string): MonthlyWage {
        this.deductionAdministrativeBan2 = deductionAdministrativeBan2;
        return this;
    }

    public getDeductionAdministrativeBan3(): string {
        return this.deductionAdministrativeBan3;
    }

    public setDeductionAdministrativeBan3(deductionAdministrativeBan3: string): MonthlyWage {
        this.deductionAdministrativeBan3 = deductionAdministrativeBan3;
        return this;
    }

    public getDeductionMobilPhone(): string {
        return this.deductionMobilPhone;
    }

    public setDeductionMobilPhone(deductionMobilPhone: string): MonthlyWage {
        this.deductionMobilPhone = deductionMobilPhone;
        return this;
    }

    public getDeductionReduction(): string {
        return this.deductionReduction;
    }

    public setDeductionReduction(deductionReduction: string): MonthlyWage {
        this.deductionReduction = deductionReduction;
        return this;
    }

    public getPaymentType(): string {
        return this.paymentType;
    }

    public setPaymentType(paymentType: string): MonthlyWage {
        this.paymentType = paymentType;
        return this;
    }

    public setPaymentDeductionType(paymentDeductionType: PaymentDeductionType): MonthlyWage {
        this.paymentDeductionType = paymentDeductionType;
        return this;
    }

    public getPaymentDeductionType(): PaymentDeductionType|undefined {
        return this.paymentDeductionType;
    }

    public setEntryYear(entryYear: string): MonthlyWage {
        this.entryYear = entryYear;
        return this;
    }

    public getEntryYear(): string {
        return this.entryYear;
    }

    public setEntryMonth(entryMonth: string): MonthlyWage {
        this.entryMonth = entryMonth;
        return this;
    }

    public getEntryMonth(): string {
        return this.entryMonth;
    }

    public setIsMonthlySalaryCalculationRegulated(isMonthlySalaryCalculationRegulated: boolean): MonthlyWage {
        this.isMonthlySalaryCalculationRegulated = isMonthlySalaryCalculationRegulated;
        return this;
    }

    public getIsMonthlySalaryCalculationRegulated(): boolean {
        return this.isMonthlySalaryCalculationRegulated;
    }

    public setBonus(bonus: string): MonthlyWage {
        this.bonus = bonus;
        return this;
    }

    public getBonus(): string {
        return this.bonus;
    }

    public setNightShift(nightShift: string): MonthlyWage {
        this.nightShift = nightShift;
        return this;
    }

    public getNightShift(): string {
        return this.nightShift;
    }

    public setStimulus(stimulus: string): MonthlyWage {
        this.stimulus = stimulus;
        return this;
    }

    public getStimulus(): string {
        return this.stimulus;
    }
    public setEmployeeMonthlySalaryCalculations(employeeMonthlySalaryCalculations: EmployeeMonthlySalaryCalculation): MonthlyWage {
        this.employeeMonthlySalaryCalculations = employeeMonthlySalaryCalculations;
        return this;
    }

    public getEmployeeMonthlySalaryCalculations(): EmployeeMonthlySalaryCalculation {
        return this.employeeMonthlySalaryCalculations;
    }

}
