import {Route} from 'entity-manager';
import {AbsenceType} from './entities';
import {AssociationOne} from '../../../../projects/entity-manager/src/lib/decorator/associations/association-one';
import {CrossDependency} from '../../../../projects/entity-manager/src/lib/decorator/cross-dependency';
import {Meta} from '../../../../projects/entity-manager/src/lib/service/meta/meta';
import {EntityDefaultFilters} from '../../../../projects/entity-manager/src/lib/decorator/entity-default-filters';

export enum AbsenceSubTypeEnum {
    InjuryAtWork = 'Povreda na radu',
    SickLeaveUpTo30Days = 'Bolovanje do 30 dana',
    SickLeaveOver30Days = 'Bolovanje preko 30 dana',
    Maternity = 'Porodiljsko',
    MaintenanceOfPregnancy = 'Održavanje trudnoće',
}

@Route('absence_sub_types')
@EntityDefaultFilters({
    disableVisibleFilter: 1
})
@CrossDependency(() => {
    return {
        absenceType: {
            mapping: Meta.META_ASSOCIATIONS,
            entity: AbsenceType
        }
    };
})
export class AbsenceSubType {
    protected id: string;

    @AssociationOne(AbsenceType)
    protected absenceType: AbsenceType;

    protected code: string;
    protected name: string;
    protected description: string;

    public getId(): string | undefined {
        return this.id;
    }

    public setId(id: string): this {
        this.id = id;
        return this;
    }

    public getAbsenceType(): AbsenceType | undefined {
        return this.absenceType;
    }

    public setAbsenceType(absenceType: AbsenceType): this {
        this.absenceType = absenceType;
        return this;
    }

    public getCode(): string {
        return this.code;
    }

    public setCode(code: string) {
        this.code = code;
        return this;
    }

    public getName(): string {
        return this.name;
    }

    public setName(name: string) {
        this.name = name;
        return this;
    }

    public getDescription(): string {
        return this.description;
    }

    public setDescription(description: string) {
        this.description = description;
        return this;
    }
}
