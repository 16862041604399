import {Route} from 'entity-manager';
import {DeletePermissionAwareInterface} from './delete-permission-aware.interface';

export enum PaymentDeductionTypeEnum {
    MobilePhoneBill = 'mobilePhoneBill',
    MediGroupMember = 'mediGroupMember',
    PersonalLoan = 'personalLoan',
    Credit = 'credit',
    Insurance = 'insurance',
    AdministrativeBan = 'administrativeBan',
    AdministrativeBan2 = 'administrativeBan2',
    AdministrativeBan3 = 'administrativeBan3',
    Increase = 'increase',
    Reduction = 'reduction',
    PaymentHoliday = 'paymentHoliday'
}

const NON_DELETABLE_CODES = [
    PaymentDeductionTypeEnum.MobilePhoneBill,
    PaymentDeductionTypeEnum.MediGroupMember,
    PaymentDeductionTypeEnum.AdministrativeBan,
    PaymentDeductionTypeEnum.AdministrativeBan2,
    PaymentDeductionTypeEnum.AdministrativeBan3
];

@Route('payment_deduction_types')
export class PaymentDeductionType implements DeletePermissionAwareInterface {

    protected id: string;
    protected code: string|PaymentDeductionTypeEnum|any;
    protected name: string;
    protected description: string;

    public setId(id: string) {
        this.id = id;
        return this;
    }

    public getId(): string {
        return this.id;
    }

    public setCode(code: string) {
        this.code = code;
        return this;
    }

    public getCode(): string|PaymentDeductionTypeEnum|any {
        return this.code;
    }

    public setName(name: string) {
        this.name = name;
        return this;
    }

    public getName(): string {
        return this.name;
    }

    public setDescription(description: string): this {
        this.description = description;
        return this;
    }

    public getDescription(): string | undefined {
        return this.description;
    }

    public isValidForDelete(): boolean {
        return !NON_DELETABLE_CODES.includes(this.code);
    }
}
